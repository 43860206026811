import { StyleSheet, Text, View } from "@react-pdf/renderer";
import { Style } from "@react-pdf/types";
import moment from "moment";

import formatPrice from "../../utils/formatPrice";
import { RestaurantInvoices } from "../../API";

export default function InvoiceItem(invoiceItem: RestaurantInvoices) {
  const hasRestaurantRefund =
    invoiceItem.hasRefund &&
    invoiceItem?.refundAmount &&
    invoiceItem?.refundAmount > 0;

  const textStyle = [
    styles.text,
    hasRestaurantRefund && { color: "red" },
  ] as Style[];

  return (
    <View
      style={{
        borderBottom: "0.5px solid lightgrey",
        marginVertical: 4,
        flexDirection: "row",
        justifyContent: "space-between",
      }}
    >
      <View>
        <Text style={textStyle}>
          {moment(invoiceItem.invoiceCreatedAt).format("DD.MM.YYYY - HH:mm") +
            " Uhr"}
        </Text>
        {/*       <Text style={textStyle ]}>
          Rechnungsnummer:{" "}
          {invoiceItem.invoiceID?.substring(0, 4).toUpperCase()}
        </Text> */}
        <Text style={textStyle}>
          Auftragsnummer: {invoiceItem.orderID?.substring(0, 4).toUpperCase()}
        </Text>
        <Text style={textStyle}>PaymentID: {invoiceItem.paymentIntentID}</Text>
        <Text style={textStyle}>
          {formatPrice(invoiceItem.invoiceTotalNet!)}
        </Text>
      </View>

      {hasRestaurantRefund && (
        <View>
          <Text style={textStyle}>
            Rückerstattung: {formatPrice(invoiceItem?.refundAmount || 0)}
          </Text>
        </View>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  text: {
    fontSize: 10,
    fontFamily: "Amble",
  },
});
