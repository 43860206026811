// Create a context
import React, { createContext, ReactElement } from "react";

import { RestaurantWeeklyBill } from "../models";
import { useAppConfigContext } from "./app-config-context";
import { useRestaurantContext } from "./restaurant-context";
import getFees from "../utils/get-fees";

type RestaurantMetadata = {
  address: string;
  customerID: string;
  name: string;
};

type InvoiceMetadata = {
  dateRange: string;
  invoiceNumber: string;
  ordersCount: string;
  weeklyBill: RestaurantWeeklyBill;
  restaurantFee: number;
};

export interface IInvoiceContext {
  closePDFInvoiceModal: () => void;
  invoiceMetadata: InvoiceMetadata;
  openPDFInvoiceModal: () => void;
  restaurantMetadata: RestaurantMetadata;
  setShowPDFInvoice: () => void;
  showPDFInvoice: boolean;

  weeklyBill: RestaurantWeeklyBill;
  onSetupInvoiceModal: (
    weeklyBill: RestaurantWeeklyBill,
    dateRange: string
  ) => void;
}

const InvoiceContext: React.Context<{}> = createContext<IInvoiceContext | {}>(
  {}
);

// const start = "2023-10-09T00:00:00.000Z"; // moment().utc().subtract(21, "day").toISOString();
// const end = "2023-10-15T00:00:00.000Z"; // moment().utc().toISOString();

const InvoiceContextProvider = ({ children }: { children: ReactElement }) => {
  const { restaurant } = useRestaurantContext();

  const [weeklyBill, setWeeklyBill] =
    React.useState<RestaurantWeeklyBill | null>(null);

  /**
   * INVOICE RESTAURANT REF
   */
  const [invoiceDateRange, setInvoiceDateRange] = React.useState<string | null>(
    null
  );

  const [showPDFInvoice, setShowPDFInvoice] = React.useState(false);

  const openPDFInvoiceModal = React.useCallback(
    () => setShowPDFInvoice(true),
    []
  );
  const closePDFInvoiceModal = React.useCallback(() => {
    setWeeklyBill(null);
    setInvoiceDateRange(null);
    setShowPDFInvoice(false);
  }, []);

  const onSetupInvoiceModal = (
    weeklyBill: RestaurantWeeklyBill,
    dateRange: string
  ) => {
    console.log("onSetupInvoiceModal", weeklyBill);
    setWeeklyBill(weeklyBill);
    setInvoiceDateRange(dateRange);
    openPDFInvoiceModal();
  };

  const { restaurantFee } = getFees(invoiceDateRange);

  const invoiceNumber =
    restaurant?.id.substring(0, 4).toUpperCase() +
    "-" +
    invoiceDateRange?.split(" - ")[1].replaceAll(".", "");

  const invoiceMetadata = {
    dateRange: invoiceDateRange,
    invoiceNumber,
    ordersCount: weeklyBill?.data?.length || 0,
    restaurantFee,
  };

  const billingAddressToString = () => {
    const billingAddress =
      restaurant?.billingDetails?.name +
      "," +
      restaurant?.billingDetails?.address?.line1 +
      "," +
      restaurant?.billingDetails?.address?.postal_code +
      " " +
      restaurant?.billingDetails?.address?.city;

    console.log("billingAddress", { billingAddress, restaurant });

    return billingAddress || restaurant?.address || "";
  };

  const restaurantMetadata = {
    name: restaurant?.billingDetails?.name || restaurant?.name,
    address: billingAddressToString().replaceAll(",", "\n"),
    customerID: restaurant?.id.substring(0, 4).toUpperCase(),
  };

  return (
    <InvoiceContext.Provider
      value={{
        closePDFInvoiceModal,
        invoiceMetadata,
        onSetupInvoiceModal,
        openPDFInvoiceModal,
        restaurantMetadata,
        setShowPDFInvoice,
        showPDFInvoice,
        weeklyBill,
      }}
    >
      {children}
    </InvoiceContext.Provider>
  );
};

export const useInvoiceContext = (): IInvoiceContext =>
  React.useContext(InvoiceContext) as IInvoiceContext;

export { InvoiceContext, InvoiceContextProvider };
